import * as React from 'react';

import TextEditorRender from 'components/utils/TextEditorRender';

const Basic = ({ pageContext }) => {
    const { page } = pageContext;
    return page.content?._rawContent && (
      <div className={`basic-wrapper ${page.slug.current}`}>
        <div className="wrapper-s">
          <TextEditorRender content={page.content._rawContent} />
        </div>
      </div>
    );
  };
/*
const Basic = ({ pageContext }) => pageContext.page.content?._rawContent && (
    <div className="basic-wrapper">
        <div className="wrapper-s">
            <TextEditorRender content={pageContext.page.content._rawContent} />
        </div>
    </div>
);
*/
export default Basic;
